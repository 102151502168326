/*******************************
         Site Overrides
*******************************/

/*--------------
   Secondary
---------------*/

.ui.secondary.menu { 
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 25%);
}